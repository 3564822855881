import React from 'react';
import './Footer.css';

export default function Footer() {
  return (
    <div className="footer-container">
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        <span className="footer-text">
          &copy; {new Date().getFullYear()} Copyright:{' '} Agocron LTD.
        </span>
      </div>
    </div>
  );
}
