import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1
          className="brand-title"
        >
          agocron
        </h1>
        <p className="brand-subtitle">
          software solutions
        </p>
        <p className="coming-soon">
          more information coming soon
        </p>
        {/* <p
          className="brand-title"
        >
          agocron
        </p>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
